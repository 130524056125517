<template>
  <div class="home">
    <v-card
      v-if="!user.approved"
      class="mx-auto mt-5 pa-2"
      max-width="344"
      variant="outlined"
    >
      <v-card-item>
        <div>
          <div class="text-overline mb-1">
            CONTA EM ANÁLISE
          </div>
          <div class="text-sm-h6 mb-1">
            Entraremos em Contato
          </div>
          <div class="text-caption">
            Estamos em fase de testes, sua conta será analisada na medida em que novas vagas forem liberadas.
          </div>
        </div>
      </v-card-item>
    </v-card>
  </div>
</template>

<script>
import Vue from "vue"
import { mapGetters } from "vuex";

export default Vue.extend({
  name: "Home",

  computed: {
    ...mapGetters({
      user: "auth/getUser"
    })
  },
})
</script>
